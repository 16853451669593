<script>
import { postAddTc, patchTc } from "@/newApi/goods";
import {
  getExpressSelect,
  getGoodsSelect,
  getGoodsAttr,
} from "@/newApi/commit";
import UploadImage from "@/components/UploadImage/UploadImage";
import Tinymce from "@/components/Tinymce";
export default {
  components: {
    UploadImage,
    Tinymce,
  },
  name: "drawerDetail",
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      expressSelect: [], //运费模板下拉选择
      fullscreenLoading: false,
      goods: [
        {
          goods_id: "", //商品id
          goods_name: "", //商品名
          goods_attr_id: "", //商品规格id
          goods_attr_name: "", //商品规格名
          type: "", //类型
          price: "", //价格
          count: "", //数量
        },
      ], //商品规格
      typesList: [
        {
          id: 1,
          name: "商品",
        },
        {
          id: 2,
          name: "服务",
        },
      ],
      rules: {
        phar_id: [{ required: true, message: "请选择药房", trigger: "change" }],
        name: [{ required: true, message: "请输入套餐", trigger: "blur" }],
        cover_img: [{ required: true, message: "选择图片", trigger: "blur" }],
        desc_pic: [{ required: true, message: "选择图片", trigger: "blur" }],
        content: [
          { required: true, message: "请输入套餐详情", trigger: "blur" },
        ],
        price: [{ required: true, message: "请套餐价格", trigger: "blur" }],
      },
      options1: [], //商品下拉数据
      attrList: [], //商品规格下拉数据
    };
  },

  //生命周期
  created() {
    console.log(this.ruleForm, 2222);
    this._getExpressSelect();
    this._getGoodsSelect();
    if (this.type == "edit") {
      if (this.ruleForm.goods.length > 0) {
        this.goods = this.ruleForm.goods;
      }
      this.ruleForm.cover_img = [this.ruleForm.cover_img];
    }
  },
  methods: {
    //药房
    changePhar(e) {
      this.options.forEach((item) => {
        if (item.id == e) {
          this.ruleForm.phar_name = item.pharmacy_name;
        }
      });
      console.log(this.ruleForm);
    },
    //通过商品获取规格
    _getGoodsAttr(id) {
      let data = {
        id: id,
      };
      getGoodsAttr(data).then((res) => {
        if (res.code == 200) {
          this.attrList = res.data;
        }
      });
    },
    //获取商品下拉
    _getGoodsSelect() {
      getGoodsSelect().then((res) => {
        if (res.code == 200) {
          this.options1 = res.data;
        }
      });
    },
    changeAttr(e, index) {
      console.log(e, index);
      this.attrList.forEach((item) => {
        if (item.goods_attr_id == e) {
          this.goods[index].goods_attr_name = item.goods_attr_name;
          this.goods[index].price = item.price;
        }
      });
    },
    //商品下拉
    changeGoods(e, index) {
      console.log(e, index);
      this.options1.forEach((item) => {
        if (item.id == e) {
          this.goods[index].goods_name = item.goods_name;
        }
      });
      this._getGoodsAttr(e);
    },
    _getExpressSelect() {
      getExpressSelect().then((res) => {
        if (res.code == 200) {
          this.expressSelect = res.data;
        }
      });
    },
    //确认按钮
    handleConfirm(formName) {
      console.log(this.ruleForm, this.goods, 1111);
      let that = this;
      //校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //检查
         /* that.goods.forEach((item) => {
            if (item.attr_name == "") {
              that.$message({
                message: "请输入规格名",
                type: "error",
              });
              return false;
            }
            if (item.attr_stock == "") {
              that.$message({
                message: "请输入商品数量",
                type: "error",
              });
              return false;
            }
          });*/
          if (that.type == "add") {
            that.ruleForm.goods = that.goods;
            // this.ruleForm.cover_img = this.ruleForm.cover_img[0];
            if(typeof this.ruleForm.cover_img != 'string'){
              this.ruleForm.cover_img = this.ruleForm.cover_img[0];
            }else{
              this.ruleForm.cover_img = this.ruleForm.cover_img;
            }
            that._postAddGoods(that.ruleForm);
          } else {
            if(typeof this.ruleForm.cover_img != 'string'){
              this.ruleForm.cover_img = this.ruleForm.cover_img[0];
            }else{
              this.ruleForm.cover_img = this.ruleForm.cover_img;
            }
            that.ruleForm.goods = that.goods;
            that._putEditGoods(that.ruleForm);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    _putEditGoods(data) {
      patchTc(data).then((res) => {
        //成功提示
        if (res.code == 200) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.$emit("getList");
          this.closeDrawer();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    _postAddGoods(data) {
      postAddTc(data).then((res) => {
        //成功提示
        if (res.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.$emit("getList");
          this.closeDrawer();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //输入规格名
    blurName() {
      //所有的规格名都改变
      this.goods.forEach((item, index) => {
        item.attr_name = this.goods[0].attr_name;
      });
    },
    //规格明细删除或者添加规格值
    addOrDel(type, index) {
      console.log(type, index,this.goods);
      if (type == "add") {
        this.goods.push({
          goods_id: "", //商品id
          goods_name: "", //商品名
          goods_attr_id: "", //商品规格id
          goods_attr_name: "", //商品规格名
          type: "", //类型
          price: 0, //价格
          count: "", //数量
          id:0,
        });
      } else {
        if (this.goods.length == 1) {
          this.$message({
            message: "至少保留一条规格",
            type: "error",
          });
          return false;
        }
        console.log(index, "index");
        //删除指定项
        this.goods.splice(index, 1);
      }
    },
    onSuccessDesc(urlAddr) {
      console.log(urlAddr, "323232");
      this.ruleForm.desc_pic = urlAddr;
      console.log(this.ruleForm);
    },
    onSuccessCover(urlAddr) {
      console.log(urlAddr);
      this.ruleForm.cover_img = urlAddr;
    },
    //取消
    closeDrawer() {
      let drawers = false;
      this.$emit("closeDrawer", drawers);
    },
  },
};
</script>

<template>
  <div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading">
    <div style="margin-bottom:20px;">
      <span style="font-size:23px;border-bottom:3px solid #1684FC;"
        >{{ type == "edit" ? "编辑" : "新增" }}套餐</span
      >
    </div>
    <div class="content">
      <div class="top-title">基础信息</div>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="所属药房" prop="phar_id">
        <el-select
          v-model="ruleForm.phar_id"
          placeholder="请选择商品药房"
          size="mini"
          filterable
          clearable
          @change="changePhar"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.pharmacy_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="套餐名称" prop="name">
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入套餐名称"
          size="mini"
          class="input"
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
      <el-form-item prop="cover_img" label="套餐封面">
        <UploadImage
          :limit="1"
          :url="ruleForm.cover_img"
          @on-success="onSuccessCover"
        ></UploadImage>
      </el-form-item>
      <el-form-item label="套餐图片" prop="desc_pic">
        <div style="wdith:100%;display: flex !important;flex-direction: row;">
          <div style="">
            <UploadImage
              :limit="9"
              :url="ruleForm.desc_pic"
              @on-success="onSuccessDesc"
            ></UploadImage>
          </div>
          <div
            style="width:200px;margin-left:20px;font-size:12px;color:#AAABAA"
          >
            图片建议上传800(宽)*600(高),(上传图片大小不要超过2M),最多上传九张
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="content" label="套餐详情">
        <tinymce v-model="ruleForm.content" :height="200" :width="700" />
      </el-form-item>
      <div class="content">
        <div class="top-title">价格库存</div>
      </div>
      <div style="display: flex;">
        <el-form-item label="套餐商品" required>
          <el-table
            :data="goods"
            border
            style="width:1000px;z-index:0;margin-top:7px"
            :header-cell-style="{ 'text-align': 'center' }"
            size="mini"
          >
            <el-table-column
              prop="type"
              label="类型"
              width="auto"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <el-select
                    v-model="scope.row.type"
                    placeholder="请选择类型"
                    size="mini"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in typesList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_name"
              label="商品"
              width="auto"
              align="center"
              height="200"
            >
              <template slot-scope="scope">
                <div>
                  <el-select
                    v-if="scope.row.type == 1"
                    v-model="scope.row.goods_id"
                    placeholder="请选择套餐商品"
                    size="mini"
                    filterable
                    clearable
                    @change="changeGoods($event, scope.$index)"
                  >
                    <el-option
                      v-for="item in options1"
                      :key="item.id"
                      :label="item.goods_name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-input
                    v-if="scope.row.type == 2"
                    v-model="scope.row.goods_name"
                    placeholder="请输入"
                    size="mini"
                    class="input1"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_attr_name"
              label="规格"
              width="auto"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <el-select
                    v-if="scope.row.type == 1"
                    v-model="scope.row.goods_attr_id"
                    placeholder="请选择规格"
                    size="mini"
                    filterable
                    clearable
                    @change="changeAttr($event, scope.$index)"
                  >
                    <el-option
                      v-for="item in attrList"
                      :key="item.goods_attr_id"
                      :label="item.goods_attr_name"
                      :value="item.goods_attr_id"
                    ></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="price"
              label="售价"
              width="auto"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="count"
              label="商品数量"
              width="auto"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <el-input
                    v-model="scope.row.count"
                    placeholder="请输入数量"
                    size="mini"
                    class="input1"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="attr_stock"
              label="操作"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <div style="display:flex;justify-content: space-around;">
                  <a
                    style="color:#1684FC"
                    @click="addOrDel('del', scope.$index)"
                    >删除</a
                  >
                  <a
                    style="color:#1684FC"
                    @click="addOrDel('add', scope.$index)"
                    >添加</a
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </div>
      <el-form-item label="套餐价格" prop="price">
        <el-input
          v-model="ruleForm.price"
          placeholder="请输入套餐价格"
          size="mini"
          class="input"
        ></el-input>
      </el-form-item>
      <el-form-item prop="is_ahead" label="是否预收" required>
        <el-radio
          v-model="ruleForm.is_ahead"
          :label="0"
          @change="$forceUpdate()"
          >否</el-radio
        >
        <el-radio
          v-model="ruleForm.is_ahead"
          :label="1"
          @change="$forceUpdate()"
          >是</el-radio
        >
        <el-input
          class="input"
          size="mini"
          :disabled="ruleForm.is_ahead == 0"
          v-model="ruleForm.ahead_money"
          placeholder="预收金额(选择是为必填)"
        ></el-input>
      </el-form-item>
      <el-form-item class="footer">
        <el-button size="small" @click="closeDrawer">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="handleConfirm('ruleForm')"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped lang="scss">
.input {
  width: 200px;
}
.input1 {
  width: 120px;
  text-align: center !important;
}
.content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  .top-title {
    font-size: 14px;
    font-weight: bold;
    padding: 7px 14px;
    background-color: #efefef;
    border-radius: 10px;
  }
}
.footer {
  position: fixed;
  top: 30px;
  right: 100px;
}
/deep/ .el-form-item {
  margin-bottom: 15px !important;
}
</style>
